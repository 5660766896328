import {createApp} from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import router from './router'
import axios from 'axios'
import store from './store'
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';


import VueAxios from 'vue-axios'
// Internationalization
import i18n from "@/translations/vue-i18n";
import VuePapaParse from 'vue-papa-parse'


import 'primevue/resources/themes/saga-blue/theme.css' // theme
import 'primevue/resources/primevue.min.css' // core css
import 'primeicons/primeicons.css' // icons
import 'primeflex/primeflex.css' // grid
import Card from 'primevue/card'
import Button from 'primevue/button'
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import AutoComplete from 'primevue/autocomplete';
import Toolbar from "primevue/toolbar";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";
import InputMask from "primevue/inputmask";
import Chip from 'primevue/chip';
import Chips from "primevue/chips";
import FileUpload from "primevue/fileupload";
import Listbox from "primevue/listbox";
import Calendar from "primevue/calendar";
import Timeline from "primevue/timeline";
import Toast from "primevue/toast";
import Fieldset from 'primevue/fieldset';
import SelectButton from 'primevue/selectbutton';
import DataView from 'primevue/dataview';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import Divider from 'primevue/divider';
import Message from 'primevue/message';
import Panel from 'primevue/panel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Tag from 'primevue/tag';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import InputSwitch from 'primevue/inputswitch';
import ContextMenu from 'primevue/contextmenu';
import Checkbox from "primevue/checkbox";

import Tooltip from 'primevue/tooltip';
import { surveyCreatorPlugin } from "survey-creator-vue";
import { surveyPlugin } from 'survey-vue3-ui';

const app = createApp(App)

app
  .use(PrimeVue)
  .use(router)
  .use(i18n)
  .use(store)
  .use(VueAxios, axios)
  .use(ToastService)
  .use(ConfirmationService)
  .use(VuePapaParse)
  .use(surveyPlugin)
  .use(surveyCreatorPlugin)

// Registering global components
app.component('Card', Card)
app.component('Button', Button)
app.component('InputText', InputText)
app.component('InputNumber', InputNumber)
app.component('AutoComplete', AutoComplete)
app.component('Toolbar', Toolbar)
app.component('Column', Column)
app.component('DataTable', DataTable)
app.component('Textarea', Textarea)
app.component('Dropdown', Dropdown)
app.component('Dialog', Dialog)
app.component('MultiSelect', MultiSelect)
app.component('InputMask', InputMask)
app.component('Chip', Chip)
app.component('Chips', Chips)
app.component('FileUpload', FileUpload)
app.component('Listbox', Listbox)
app.component('Calendar', Calendar)
app.component('Timeline', Timeline)
app.component('Toast', Toast)
app.component('Fieldset', Fieldset)
app.component('SelectButton', SelectButton)
app.component('DataView', DataView)
app.component('ConfirmDialog', ConfirmDialog)
app.component('ConfirmPopup', ConfirmPopup)
app.component('Divider', Divider)
app.component('Message', Message)
app.component('Panel', Panel)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('Tag', Tag)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('InputSwitch', InputSwitch)
app.component('ContextMenu', ContextMenu)
app.component('Checkbox', Checkbox)

app.directive('tooltip', Tooltip);

app.mount('#app')
