<template>
  <h1>{{ $t('admin.massevaluation.title') }}</h1>
  <h3 class="p-mb-6">{{ $t('admin.massevaluation.description') }}</h3>

  <div class="p-grid p-col-12">

    <Accordion class="p-col-12" :multiple="false" activeIndex="1">
      <AccordionTab header="Importer un fichier">

        <!--    File upload    -->
        <div class="p-mb-3">
          <FileUpload mode="advanced" name="file[]" :customUpload="true" :show-upload-button="false"
                      @uploader="onUpload" :auto="true"
                      accept="text/csv"
                      :maxFileSize="15000000"/>
          <small v-show="validationErrors.teachings && submitted"
                 class="p-error">{{ validationErrors.teachings }}</small>
        </div>

        <!--------- Teachings ------------>
        <DataTable v-if="teachings" :value="teachings"
                   :paginator="true"
                   :rows="20"
                   ref="dt"
                   :loading="loading">

          <Column field="Enseignement" header="Enseignement" sortable></Column>
          <Column field="Horaire(s)" header="Niveau" sortable></Column>
          <Column field="Responsable Email" header="Responsable" sortable></Column>
          <Column field="Intervenants (non assistants)" header="Intervenants (non assistants)" sortable></Column>

          <template #empty>
            No records found.
          </template>

        </DataTable>

        <div class="p-grid p-mt-3">
          <div class="p-col-4 p-fluid">
            <Dropdown v-model="faculty" :options="faculties" optionLabel="name"
                      placeholder="Select a faculty" :class="{'p-invalid': validationErrors.faculty && submitted}"/>
            <small v-show="validationErrors.faculty && submitted"
                   class="p-error">{{ validationErrors.faculty }}</small>
          </div>

          <div class="p-col-4 p-fluid">
            <Dropdown placeholder="Select a year" v-model="acadyear" :options="years"
                      :class="{'p-invalid': validationErrors.acadyear && submitted}"/>
            <small v-show="validationErrors.acadyear && submitted"
                   class="p-error">{{ validationErrors.acadyear }}</small>
          </div>

          <div class="p-col-4">
            <SelectButton id="semester" v-model="semester" :options="semesters" optionLabel="name"
                          optionValue="value" :class="{'p-invalid': validationErrors.semester && submitted}"/>
            <small v-show="validationErrors.semester && submitted"
                   class="p-error">{{ validationErrors.semester }}</small>
          </div>
        </div>

        <!--------- Dates ------------>
        <div class="p-grid p-fluid p-mt-3">
          <div class="p-field p-col-4">
            <label>{{ $t("form.step3.fields.sendingdate.label") }}</label>
            <Calendar v-model="sending_date" :showTime="true"
                      :class="{'p-invalid': validationErrors.sending_date && submitted}"/>
            <small v-show="validationErrors.sending_date && submitted"
                   class="p-error">{{ validationErrors.sending_date }}</small>
          </div>

          <div class="p-field p-col-4 p-as-end">
            <label>{{ $t("form.step3.fields.closingdate.label") }}</label>
            <Calendar v-model="closing_date" :showTime="true" :min-date="sending_date"
                      :class="{'p-invalid': validationErrors.closing_date && submitted}"/>
            <small v-show="validationErrors.closing_date && submitted"
                   class="p-error">{{ validationErrors.closing_date }}</small>
          </div>

          <div class="p-field p-col-4 p-as-end">
            <label>ID du modèle de questionnaire</label>
            <Dropdown :options="surveys" optionLabel="_id" v-model="survey"
                      :class="{'p-invalid': validationErrors.survey && submitted}"></Dropdown>
            <small v-show="validationErrors.survey && submitted"
                   class="p-error">{{ validationErrors.survey }}</small>
          </div>

        </div>

        <Button label="Créer les demandes d'évaluation" icon="pi pi-plus" class="p-button-success p-mr-2 p-button-sm"
                @click="createRequests"/>
      </AccordionTab>
    </Accordion>

    <div class="p-col-12 p-mt-6">
      <DataTable ref="dt" sortMode="multiple" :value="mass_eval_requests"
                 :paginator="true" class="p-datatable-customers"
                 :rows="10"
                 dataKey="_id" filterDisplay="menu"
                 responsiveLayout="scroll">

        <template #empty>
          {{ $t('misc.empty') }}
        </template>

        <template #loading>
          {{ $t('misc.loading') }}
        </template>

        <template #header>
          <div class="flex justify-content-between">

            <Dropdown v-if="mass_eval_ids" v-model="selected_mass_eval_id" :options="mass_eval_ids"
                      placeholder="Select a mass eval id" option-value="value" option-label="label"
                      @change="filterRequests"/>

            <Button v-if="selected_mass_eval_id" label="Valider ces demandes" icon="pi pi-check"
                    class="p-button-success p-mr-2 p-ml-2 p-button-sm"
                    @click="validateRequests"/>
            <Button v-if="selected_mass_eval_id" label="Supprimer ces demandes" icon="pi pi-trash"
                    class="p-button-danger p-mr-2 p-button-sm" @click="deleteRequests"/>
          </div>
        </template>

        <Column field="number" filter-field="number" type="number" :sortable="true"
                :header="$t('my_requests.columns.id')" style="max-width: 120px">
          <template #body="{data}">
            {{ data.number }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" fil type="text" class="p-column-filter" style="width: 40px"/>
          </template>
        </Column>

        <!--   mass_eval_id column     -->
        <Column field="mass_eval_id" filter-field="mass_eval_id" type="number" :sortable="true"
                :header="$t('my_requests.columns.mass_eval_id')" style="max-width: 120px">
          <template #body="{data}">
            {{ data.mass_eval_id }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" fil type="text" class="p-column-filter" style="width: 40px"/>
          </template>
        </Column>

        <Column field="teaching_name" filter-field="teaching_name" :sortable="true" style="max-width: 200px"
                :header="$t('my_requests.columns.teaching_names.title')">
          <template #body="{data}">
            {{ data.teaching_name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter" @input="filterCallback()"
                       placeholder="Search by teaching name"/>
          </template>
        </Column>

        <Column field="speakers_list" filter-field="speakers_list" style="max-width: 230px"
                :header="$t('my_requests.columns.teachers')">
          <template #body="{data}">
            {{ data.speakers_list }}
          </template>

          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter" @input="filterCallback()"
                       placeholder="Search by speakers"/>
          </template>
        </Column>

        <Column field="faculty" filter-field="faculty" :sortable="true" style="max-width: 200px"
                :header="$t('my_requests.columns.faculty')">
          <template #body="{data}">
            {{ data.faculty }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter" @input="filterCallback()"
                       placeholder="Search by faculty"/>
          </template>
        </Column>

        <Column field="academic_year" filter-field="academic_year" :sortable="true" style="max-width: 150px"
                :header="$t('my_requests.columns.academic_year')">
          <template #body="{data}">
            {{ data.academic_year }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter" @input="filterCallback()"
                       placeholder="Search by academic year"/>
          </template>
        </Column>

        <Column field="semester" filter-field="semester" :sortable="true" style="max-width: 150px"
                :header="$t('my_requests.columns.semester')">
          <template #body="{data}">
            {{ data.semester === 'P' ? $t('misc.spring') : $t('misc.autumn') }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="semesters" option-value="value"
                      option-label="label"
                      placeholder="Select One" class="p-column-filter">

            </Dropdown>
          </template>
        </Column>

        <Column field="survey_name" filter-field="survey_name" :sortable="true" style="max-width: 250px"
                :header="$t('my_requests.columns.survey_name')">
          <template #body="{data}">
            {{
              data.survey_name[$i18n.locale]
              || data.survey_name.default
              || data.survey_name
            }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter" @input="filterCallback()"
                       placeholder="Search by survey name"/>
          </template>
        </Column>

        <Column field="survey_type" filter-field="survey_type" :sortable="true" style="max-width: 150px"
                :header="$t('my_requests.columns.survey_type')">
          <template #body="{data}">
            <Tag :severity="data.survey_type === 'existing' ? 'info' : 'warning'"
                 rounded> {{ data.survey_type === 'existing' ? $t('misc.existing') : $t('misc.custom') }}
            </Tag>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="survey_types"
                      placeholder="Select One" class="p-column-filter">
              <template #option="slotProps">
                <Tag :value="slotProps.option" :severity="slotProps.option === 'custom' ? 'warning' : 'info'">
                  {{ slotProps.option === 'existing' ? $t('misc.existing') : $t('misc.custom') }}
                </Tag>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column field="closing_date" filter-field="closing_date" sort-field="closing_date_timestamp"
                style="max-width: 130px"
                :sortable="true" :header="$t('my_requests.columns.closing_date.title')">
          <template #body="{data}">
            {{ data.closing_date }}
          </template>
        </Column>

        <Column field="status" header="Status" filter-field="status.status_code" sort-field="status.status_code"
                :sortable="true" style="max-width: 200px">
          <template #body="{data}">
            <Tag class="p-mt-1 p-ml-1" :severity="data.status?.class"
                 :value="$t('request_detail.status_' + data.status?.status_code)"></Tag>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" option-value="code"
                      option-label="label"
                      placeholder="Select One" class="p-column-filter">
              <template #option="slotProps">
                <Tag :value="slotProps.option.code" :severity="slotProps.option.class">{{ slotProps.option.label }}
                </Tag>
              </template>
            </Dropdown>
          </template>
        </Column>


        <Column field="actions" header="Actions">
          <template #body="{data}">
            <Button @click="$router.push({name: 'request_detail', params: {id: data._id}})" icon="pi pi-eye"
                    class="p-mr-2 p-button-info p-button-rounded left" text/>
            <Button v-if="is_admin" icon="pi pi-trash" class="p-button-danger p-button-rounded p-mr-2"
                    @click="deleteRequest(data._id)" text/>
          </template>
        </Column>

      </DataTable>
    </div>
  </div>


</template>
<script>
import SylviaService from '@/Services/sylvia.service'
import FacultiesService from "@/Services/faculties.service";
import RequestService from '@/Services/request.service'
import SurveyService from "@/Services/survey.service";

export default {
  name: 'mass-evaluation',
  data() {
    return {
      is_admin: false,
      is_editor: false,
      active: 0,
      validationErrors: {},
      submitted: false,
      faculties: [],
      faculty: null,
      teachings: null,
      acadyear: null,
      semester: null,
      semesters: [
        {name: this.$t("form.step1.fields.semester.first"), value: 'P'},
        {name: this.$t("form.step1.fields.semester.second"), value: 'A'}
      ],
      years: [],
      loading: false,
      sending_date: null,
      closing_date: null,
      survey: null,
      surveys: [],
      mass_eval_requests: [],
      selected_mass_eval_id: null,
      mass_eval_ids: []
    }
  },
  beforeMount() {
    this.is_admin = sessionStorage.getItem('currentrole') === 'admin'
    this.is_editor = sessionStorage.getItem('currentrole') === 'editor'
    let current_year = new Date().getFullYear()
    this.years = [current_year - 2, current_year - 1, current_year]
    FacultiesService.getFaculties().then(result => {
      this.faculties = result.data
    })
    SurveyService.getSurveys('is_for_mass_evaluation=true').then(response => {
      this.surveys = response.data
    })
    RequestService.getRequests('mass_eval_id=!null').then(response => {
      this.mass_eval_requests = response.data
      this.getMassEvalIdsFromRequests()
    })
  },
  methods: {
    getMassEvalIdsFromRequests() {
      this.mass_eval_ids = []
      this.mass_eval_ids.push({label: 'Tous', value: null});
      this.mass_eval_requests.forEach(request => {
        if (request.mass_eval_id && !this.mass_eval_ids.some(id => id.value === request.mass_eval_id)) {
          this.mass_eval_ids.push({label: request.mass_eval_id, value: request.mass_eval_id});
        }
      });
    },
    createRequests() {
      this.submitted = true

      if (this.validateForm()) {
        let data = {
          teachings: this.teachings,
          faculty: this.faculty,
          semester: this.semester,
          acadyear: this.acadyear,
          sending_date: this.sending_date,
          closing_date: this.closing_date,
          original_survey: this.survey.json_definition
        }

        RequestService.createMassEvaluation(data)
          .then(result => {
            this.$toast.add({severity: 'success', summary: 'Demandes de conseil créées', life: 3000})
            RequestService.getRequests('mass_eval_id=!null').then(response => {
              this.mass_eval_requests = response.data
              this.getMassEvalIdsFromRequests()
            })
          })
          .catch(error => {
            this.$toast.add({severity: 'error', summary: 'Erreur lors de la création des demandes', life: 3000})
          })
      }
    },
    validateRequests() {
      this.$confirm.require({
        target: event.currentTarget,
        message: 'Êtes-vous sûr-e de vouloir valider ces requêtes ?',
        acceptLabel: this.$t('misc.yes'),
        rejectLabel: this.$t('misc.no'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          RequestService.validateMassEvaluation(this.selected_mass_eval_id)
            .then(result => {
              this.$toast.add({severity: 'success', summary: 'Demandes validées', life: 3000})
              RequestService.getRequests('mass_eval_id=!null').then(response => {
                this.mass_eval_requests = response.data
                this.getMassEvalIdsFromRequests()
              })
            })
            .catch(error => {
              this.$toast.add({severity: 'error', summary: 'Erreur lors de la validation des demandes', life: 3000})
            })
        }
      })
    },
    deleteRequest(id) {
      this.$confirm.require({
        target: event.currentTarget,
        message: this.$t('misc.are_you_sure'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          RequestService.deleteRequest(id)
            .then(() => {
              this.$toast.add({severity: 'success', summary: 'Requête supprimée', life: 3000})
              RequestService.getRequests('mass_eval_id=!null').then(response => {
                this.mass_eval_requests = response.data
                this.getMassEvalIdsFromRequests()
              })
            })
        }
      })
    },
    deleteRequests() {
      this.$confirm.require({
        target: event.currentTarget,
        message: 'Êtes-vous sûr-e de vouloir supprimer ces requêtes ?',
        acceptLabel: this.$t('misc.yes'),
        rejectLabel: this.$t('misc.no'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          RequestService.deleteMassEvaluation(this.selected_mass_eval_id)
            .then(result => {
              this.$toast.add({severity: 'success', summary: 'Demandes supprimées', life: 3000})
              RequestService.getRequests('mass_eval_id=!null').then(response => {
                this.mass_eval_requests = response.data
                this.getMassEvalIdsFromRequests()
              })
            })
            .catch(error => {
              this.$toast.add({severity: 'error', summary: 'Erreur lors de la suppression des demandes', life: 3000})
            })
        }
      })

    },
    filterRequests() {
      if (this.selected_mass_eval_id) {
        RequestService.getRequests(`mass_eval_id=${this.selected_mass_eval_id}`).then(response => {
          this.mass_eval_requests = response.data
        })
      } else {
        RequestService.getRequests('mass_eval_id=!null').then(response => {
          this.mass_eval_requests = response.data
        })
      }
    },
    onUpload(event) {
      this.$papa.parse(event.files[0], {
        skipEmptyLines: true,
        header: true,
        complete: (teachings) => {
          teachings.data.forEach(teaching => {
            teaching['Courriels Intervenants'] = teaching['Intervenants (non assistants)'].match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)?.join(', ') ?? ''
          })
          this.teachings = teachings.data
        }
      });

    },

    validateForm() {
      this.validationErrors = {}

      if (this.teachings === null) this.validationErrors.teachings = 'Le fichier contient une erreur.'
      if (this.faculty === null) this.validationErrors.faculty = 'La faculté n\'est pas renseignée'
      if (this.semester === null) this.validationErrors.semester = 'Le semestre n\'est pas renseigné';
      if (this.acadyear === null) this.validationErrors.acadyear = 'L\'année académique n\'est pas renseignée';
      if (this.sending_date === null) this.validationErrors.sending_date = 'La date d\'envoi n\'est pas renseignée';
      if (this.closing_date === null) this.validationErrors.closing_date = 'La date de clôture n\'est pas renseignée';
      if (this.survey === null) this.validationErrors.survey = 'Le questionnaire n\'est pas renseigné';

      const result = Object.keys(this.validationErrors).length === 0
      !result ? this.$toast.add({
        severity: 'error',
        summary: 'Veuillez renseigner les champs manquants',
        life: 3000
      }) : null

      return result
    }
  }
}
</script>
<style>
</style>
