<template>

  <div class="p-grid">
    <div class="p-col-2">
      <Button @click="$router.push({name: 'request_list'})" icon="pi pi-backward" class="p-button-text"
              :label="$t('misc.back')"/>
    </div>
  </div>

  <h1>{{ $t('request_detail.title') }} no {{ request?.number }}</h1>

  <div class="p-grid p-jc-center">
    <div class="p-col-12">{{ $t('request_detail.status') }} :
      <Tag v-if="request" :severity="request.status?.class" class="p-ml-2"
           :value="$t('request_detail.status_' + request.status?.status_code)"></Tag>
    </div>

    <!--  Results info  -->
    <div v-if="current_role==='admin' || current_role === 'editor'" class="p-col-12">
      <h4 class="p-grid p-jc-center">{{ $t('request_detail.acceptance_rate') }} :
        {{ acceptance_rate }}%
      </h4>
    </div>
    <div v-else class="p-col-12">
      <h4 class="p-grid p-jc-center">{{ $t('request_detail.answer_rate') }} :
        {{ Math.ceil(nb_answers / request?.students.length * 100) }}%
        ({{ nb_answers }} sur {{ request?.students.length }})
      </h4>
    </div>
  </div>

  <h3>{{ error?.error }}</h3>


  <Toolbar>
    <template #start>
      <Button v-if="show_results_button" @click="$router.push({'name': 'request_results'})" icon="pi pi-chart-bar"
              class="p-ml-2 p-button-success"
              :label="$t('request_detail.access_results')"/>

      <ConfirmDialog group="templating">
        <template #message="slotProps">
          <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
            <i :class="slotProps.message.icon" class="text-6xl text-primary-500"></i>
            <p>{{ $t('request_detail.reopen_request_text') }}</p>
            <Calendar v-model="new_closing_date" :showTime="true" :min-date="today" :disabledDays="[0,6]" dateFormat="dd.mm.yy" :locale="$i18n.locale"/>
          </div>
        </template>
      </ConfirmDialog>
      <Button v-if="current_role === 'admin' && request?.status.status_code === 3 && edit_mode" @click="reopenRequest" icon="pi pi-clock"
              class="p-ml-2 p-button-warning"
              :label="$t('request_detail.reopen_request')"/>


      <Button v-if="current_role === 'admin' || current_role ==='editor'" @click="$router.push({'name': 'request_results_processing'})"
              icon="pi pi-cog" class="p-ml-2 p-button-info"
              :label="$t('request_detail.administration')"/>
      <Button v-if="current_role === 'admin' && request?.status.status_code === 2 && edit_mode"
              icon="pi pi-times" class="p-ml-2 p-button-warning"
              :label="$t('request_detail.close_survey')" @click="closeSurvey"/>

      <Button v-if="request && request.status.status_code === 0 && current_role === 'admin'" @click="validateRequest" icon="pi pi-check"
              class="p-button-warning p-ml-2"
              :label="$t('request_detail.validate_request')"/>

      <Button v-if="current_role === 'admin' && edit_mode" :label="$t('request_detail.delete')" icon="pi pi-trash" class="p-button-danger p-ml-2"
              @click="deleteRequest"/>
    </template>

    <template #end>
      <Button v-if="edit_mode" @click="updateRequest" icon="pi pi-save" :label="$t('misc.save')"
              class="p-button-success p-mr-2"/>
      <span v-if="show_edit_mode" class="p-mr-2">{{ $t('request_detail.edit_mode') }}</span>
      <InputSwitch v-if="show_edit_mode" v-model="edit_mode"/>
    </template>
  </Toolbar>

  <TabView v-if="error === null">
    <TabPanel :header="$t('request_detail.request_detail_tab_title')">

      <div class="p-grid p-field">
        <label class="p-col-3">{{ $t("form.step1.fields.teachingname.label") }} : </label>
        <!-- edit_mode -->
        <div class="p-inputgroup p-col-9" v-if="edit_mode">
          <span class="p-inputgroup-addon"><InputText v-model="request.teaching_name"/></span>
          <span class="p-inputgroup-addon"><SelectButton id="semester" v-model="request.semester" :options="semesters"
                                                         option-value="value" option-label="name"></SelectButton></span>
          <span class="p-inputgroup-addon"><Dropdown v-model="request.academic_year" id="year" :options="years" option-label="label" option-value="value" /></span>
        </div>

        <div class="p-col-9" v-else>
          <strong>{{ request?.teaching_name }} - {{
              request?.semester === 'P' ? $t('form.step1.fields.semester.first') : $t('form.step1.fields.semester.second')
            }} {{ request?.semester === 'P' ? request?.academic_year + 1 : request?.academic_year }}</strong>
        </div>
      </div>


      <div class="p-grid p-field">
        <label class="p-col-3">{{ $t("form.step1.fields.faculty.label") }} : </label>
        <!-- edit_mode -->
        <div class="p-inputgroup p-col-9" v-if="edit_mode">
          <span class="p-inputgroup-addon">
            <Dropdown id="faculty" :options=facultiesList option-label="name" option-value="name" v-model="request.faculty"></Dropdown>
          </span>
        </div>


        <div class="p-col-9" v-else>
          <p><b>{{ request?.faculty }}</b></p>
        </div>
      </div>


      <div class="-p-grid">
        <div class="p-grid p-field">
          <label class="p-col-3">{{ $t("form.step1.fields.courses.label") }} : </label>
          <div class="p-col-9">
            <Listbox :options="request?.courses" disabled>
              <template #option="slotProps">
                <div>
                  <span>{{ slotProps.option.name }} ({{ slotProps.option.periodicity }})</span>
                </div>
              </template>
              <template #empty>
                <div>{{ $t("misc.no_courses") }}</div>
              </template>
            </Listbox>
          </div>
        </div>


        <div class="p-grid p-field">
          <label class="p-col-3">{{ $t("form.step1.fields.speakers.label") }} : </label>
          <!-- edit_mode -->
          <div class="p-col-9 p-inputgroup" v-if="edit_mode">
            <span v-if="request" class="p-inputgroup-addon"><SpeakersDatatable id="speakers"
                                                                               :speakers="request.speakers"
                                                                               :validationErrors="validation_errors"
                                                                               @value-change="updateSpeakersList"></SpeakersDatatable></span>
          </div>

          <div class="p-col-9" v-else>
            <Listbox :options="request?.speakers">
              <template #option="slotProps">
                <div>
                    <span>{{ slotProps.option.name }} ({{ slotProps.option.email }}) - {{
                        slotProps.option.role
                      }}</span>
                </div>
              </template>
            </Listbox>
          </div>
        </div>
      </div>


      <div class="p-field p-grid">
        <label class="p-col-3">{{ $t('form.step2.fields.student_list.label') }}</label>
        <!-- edit_mode -->
        <div class="p-inputgroup p-col-9" v-if="edit_mode">
          <span v-if="request" class="p-inputgroup-addon">
            <Chips v-model="request.students" separator="\n"
                   :class="{'p-invalid': (validation_errors.nostudent || validation_errors.incorrectemails) && submitted}">
            </Chips>
          </span>
          <small v-show="validation_errors.nostudent && submitted"
                 class="p-error">{{ $t("form.step2.fields.student_list.error1") }}</small>
          <small v-show="validation_errors.incorrectemails && submitted"
                 class="p-error">{{ $t("form.step2.fields.student_list.error2") }}
            <ul id="incorrectemails">
              <li v-for="email in incorrect_emails">
                {{ email }}
              </li>
            </ul>
          </small>
        </div>

        <div class="p-col-9" @contextmenu="onRightClick" v-else>
          <Chips v-if="request" v-model="request.students" disabled="disabled"></Chips>
        </div>
      </div>
      <ContextMenu ref="menu" :model="items"/>


    </TabPanel>


    <TabPanel :header="$t('request_detail.survey_detail_tab_title')">
      <div class="p-grid">

        <div class="p-col-8">
          <!--begin edit_mode -->
          <Button v-if="edit_mode" class="p-mb-5" :label="$t('request_detail.add_reminder')" icon="pi pi-plus"
                  iconPos="right" @click="openModal"/>

          <Dialog :header="$t('request_detail.add_reminder_description')" v-model:visible="display_modal"
                  :breakpoints="{'960px': '75vw', '640px': '90vw'}" :modal="true">
            <p>
              <Calendar v-model="new_reminder_date" :min-date="min_date" :max-date="max_date" :showTime="true"
                        :inline="true" :disabledDays="[0,6]" dateFormat="dd.mm.yy"/>
            </p>
            <template #footer>
              <Button :label="$t('misc.back')" badge-class="warning" icon="pi pi-times" @click="closeModal"
                      class="p-button-danger"/>
              <Button :label="$t('misc.save')" icon="pi pi-check" @click="addReminder" autofocus
                      class="p-button-success"/>
            </template>
          </Dialog>

          <Timeline v-if="request && edit_mode" :value="request.steps" layout="vertical" align="alternate">
            <template #content="slotProps">
              <p>
                {{ $t('request_detail.tasks.' + slotProps.item.type + '.label') }}
                <Calendar v-model="slotProps.item.date"
                          :disabled="new Date(slotProps.item.date).getTime() < today.getTime()" :showTime="true"
                          :min-date="today" :disabledDays="[0,6]" dateFormat="dd M yy" :locale="$i18n.locale"/>
                <Button outlined v-if="slotProps.item.type === 'remind'" icon="pi pi-times" :label="$t('misc.delete')" class="p-button-rounded p-button-danger p-ml-2"
                        @click="request.steps.splice(slotProps.index, 1)"/>
              </p>
            </template>
          </Timeline>
          <p v-show="validation_errors.incorrectdates && submitted" class="p-error">{{ $t('request_detail.date_error') }}</p>
          <!-- end edit_mode -->

          <Timeline v-if="request && !edit_mode" :value="request.steps" layout="vertical" align="alternate">
            <template #content="slotProps">
              {{ $t('request_detail.tasks.' + slotProps.item.type + '.label') }}
              <p>
                <b>{{
                    new Date(slotProps.item.date).toLocaleDateString($i18n.locale, {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                  }}</b>
              </p>
            </template>
          </Timeline>
        </div>

        <div class="p-col-4 p-mt-2">
          <label>{{ $t('form.step3.fields.surveytype.existing.surveychoice.label') }} :</label>
          <div>
            <p><b>{{ typeof request?.survey_name === 'object' ? request?.survey_name[$i18n.locale] : request?.survey_name }}</b>,
              {{ $t('form.step3.fields.surveytype.' + request?.survey_type + '.label') }}</p>
            <p class="p-mb-5" v-if="request?.survey_modifications"><b>{{ request?.survey_modifications }}</b></p>

          </div>

          <label>{{ $t('form.step3.fields.resulttime.label') }}</label>
          <p v-if="!edit_mode"><b>{{ request?.receipt_time_limit }} {{ $t('misc.days') }}</b></p>
          <!--begin edit_mode -->
          <div class="p-mb-2 p-mt-2">
            <Dropdown v-if="edit_mode" v-model="request.receipt_time_limit" :options="receipt_time_limits"
                      optionLabel="name" optionValue="value"
                      :placeholder='$t("form.step3.fields.resulttime.placeholder")'/>
          </div>
          <!-- end edit_mode -->

          <label>{{ $t('form.step3.fields.synthesis_required.label') }}</label>
          <p v-if="!edit_mode"><b>{{ request?.synthesis_required ? $t('misc.yes') : $t('misc.no') }}</b></p>
          <!-- begin edit mode -->
          <SelectButton v-if="edit_mode" v-model="request.synthesis_required"
                        :options="[{name: $t('misc.yes'), value: true}, {name: $t('misc.no'), value: false}]"
                        optionLabel="name" optionValue="value" class="p-mt-2"/>
          <!-- end edit_mode -->
        </div>

      </div>
      <div class="p-grid">
        <Button v-if="current_role === 'admin'" @click="$router.push({'name': 'admin_survey_creator'})" icon="pi pi-pencil"
                class="p-button-info p-mb-2"
                label="Modifier le questionnaire"/>

        <iframe class="p-col-12" v-if="request && current_role === 'admin'"
                :src="BACKEND_URL + '/surveys/preview/' + request._id"></iframe>
      </div>

    </TabPanel>
  </TabView>

</template>
<script>
import RequestService from '@/Services/request.service.js'
import {useRoute} from 'vue-router'
import AdminSurveyCreator from "@/components/form/AdminSurveyCreator";
import SpeakersDatatable from "@/components/form/SpeakersDatatable";
import {Validation} from "@/helpers/validation";
import SurveyService from "@/Services/survey.service";
import ResultsService from "@/Services/results.service";
import {generalhelper} from "@/helpers/generalhelper";
import {requesthelper} from "@/helpers/requesthelper";
import FacultiesService from "@/Services/faculties.service";
import ReportsService from "@/Services/reports.service";

export default {
  name: 'requests-details',
  components: {AdminSurveyCreator, SpeakersDatatable},
  data() {
    return {
      BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
      is_modification_allowed: false,
      error: null,
      original_survey_id: null,
      original_survey_json_save: null,
      request: null,
      current_role: '',
      show_edit_survey_tab: false,
      show_results_button: false,
      edit_mode: false,
      show_edit_mode: false,
      semesters: [
        {name: this.$t("form.step1.fields.semester.first"), value: 'P'},
        {name: this.$t("form.step1.fields.semester.second"), value: 'A'}
      ],
      receipt_time_limits: generalhelper.getReceiptTimeLimits(this.$t),
      faculties_list: [],
      surveys: null,
      years: [],
      validation_errors: {},
      incorrect_emails: [],
      id: null,
      submitted: false,
      display_modal: false,
      new_reminder_date: null,
      min_date: null,
      max_date: null,
      today: null,
      nb_answers: 0,
      acceptance_rate: 0,
      items: [
        {label: this.$t('misc.copy'), icon: 'pi pi-copy', command: () => this.copyStudentsList()},
        {label: this.$t('misc.toXLS'), icon: 'pi pi-file-excel', command: () => this.exportToXLS()},
      ],
      new_closing_date: null
    }
  },
  mounted() {
    this.id = useRoute().params.id
    this.today = new Date()
    RequestService.getRequest(this.id)
      .then(request => {
        this.request = request.data.result
        /* Can be edited if admin or (user and not in PROCESSING_RESULTS state) */
        this.show_edit_mode = this.current_role === 'admin' || (this.current_role === 'user' && this.request.status.status_code <= 2)
        /* (admin OR user) AND (request.status = results_available) */
        this.show_results_button = (this.current_role === 'user' && this.request.status.status_code === 4) || this.current_role === 'admin'
        //is user is admin, display acceptance rate ; if user has no admin role, display number of answers
        if (this.current_role === 'admin' || this.current_role === 'editor') {
          ReportsService.getSimpleGlobalAcceptance([this.request], false).then(result => {
            this.acceptance_rate = result.data[0].acceptance_rate
          })
        } else {
          ResultsService.getResultsCount(this.id).then(response => {
            this.nb_answers = response.data
          })
        }
      })
      .catch(error => {
        this.error = error.response?.data
      })
    this.current_role = sessionStorage.getItem('currentrole') ?? ''
    this.years = generalhelper.getAcadYears()
    //modification is allowed if current role is admin or user and request status is not 'closed'
    this.is_modification_allowed = (this.current_role === 'admin' || this.current_role === 'user')
      && [0,1,2,3].includes(this.request?.status.status_code)

    SurveyService.getSurveys('is_visible=true').then(response => {
      this.original_survey_id = this.$t('request_detail.original_survey')
      this.surveys = response.data
      this.surveys.unshift({
        _id: this.$t('request_detail.original_survey'),
        json_definition: this.request?.original_survey
      })
    })

    FacultiesService.getFaculties().then(response => {
      this.facultiesList = response.data
    })
  },
  methods: {
    validateRequest() {
      this.$confirm.require({
        target: event.currentTarget,
        message: this.$t('misc.are_you_sure'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          RequestService.validateRequest(this.request._id)
            .then(response => {
              this.$toast.add({severity: 'success', summary: 'Requête validée', life: 3000})
              this.request = response.data.result
              this.show_edit_survey_tab = false
            })
        }
      })
    },
    updateRequest() {
      this.submitted = true
      if (this.validateFields()) {
        RequestService.updateRequest(this.request)
          .then(response => {
            this.$toast.add({severity: 'success', summary: 'Requête mise à jour', life: 3000})
            this.edit_mode = false
            this.request = response.data.result
          })
      } else {
        this.$toast.add({severity: 'error', summary: this.$t('misc.validation_error'), life: 3000})
      }
    },
    deleteRequest() {
      this.$confirm.require({
        target: event.currentTarget,
        message: this.$t('misc.are_you_sure'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          RequestService.deleteRequest(this.request._id)
            .then(() => {
              this.$toast.add({severity: 'success', summary: 'Requête supprimée', life: 3000})
              this.$router.push({name: 'request_list'})
            })
        }
      })
    },
    updateSpeakersList(speakersList) {
      this.request.speakers = speakersList
    },
    validateFields() {
      let validation = Validation.isTargetAudienceValid(this.request.students, this.validationErrors, this.incorrectemails)
      this.incorrect_emails = validation.incorrect_emails
      //check dates
      let closing_date = new Date(this.request.steps.find(step => step.type === 'close').date)
      this.request.steps.forEach(step => {
        if (new Date(step.date) > closing_date) {
          validation.validation_errors.incorrectdates = true;
          validation.is_valid = false;
        }
      })
      this.validation_errors = validation.validation_errors

      return validation.is_valid
    },
    openModal() {
      this.display_modal = true;
      let send_date = new Date(this.request.steps.find(step => step.type === 'send').date)
      this.min_date = (send_date < new Date()) ? new Date() : send_date
      this.max_date = new Date(this.request.steps.find(step => step.type === 'close').date)
    },
    closeModal() {
      this.display_modal = false;
    },
    addReminder() {
      this.request.steps.push({
        type: 'remind',
        date: this.new_reminder_date
      })
      this.request.steps.sort((a,b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime()
      })

      this.new_reminder_date = null
      this.display_modal = false;
    },
    onRightClick() {
      this.$refs.menu.show(event);
    },
    copyStudentsList() {
      navigator.clipboard.writeText(this.request.students.join(','))
      this.$toast.add({severity: 'success', summary: this.$t('request_detail.student_list_copy'), life: 2000})
    },
    exportToXLS(){
      let workbook = XLSX.utils.book_new();
      let workSheet = XLSX.utils.json_to_sheet(this.request.students.map(student => {return {email: student}}), {skipHeader: true})
      XLSX.utils.book_append_sheet(workbook, workSheet, 'Students');
      XLSX.writeFile(workbook, this.request.teaching_name + '.xlsx');
      this.$toast.add({severity: 'success', summary: this.$t('request_detail.student_list_export'), life: 2000})
    },
    closeSurvey() {
      this.$confirm.require({
        target: event.currentTarget,
        message: this.$t('misc.are_you_sure'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.request.status = requesthelper.PROCESSING_RESULTS
          RequestService.updateRequest(this.request)
            .then(response => {
              this.$toast.add({severity: 'success', summary: this.$t('request_detail.survey_closed'), life: 3000})
              this.request = response.data.result
            })
        }
      })
    },
    reopenRequest() {
      this.$confirm.require({
        group: 'templating',
        header: this.$t('request_detail.reopen_request'),
        message: this.$t('request_detail.reopen_request_text'),
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectClass: 'p-button-outlined p-button-sm',
        acceptClass: 'p-button-sm',
        rejectLabel: 'Cancel',
        acceptLabel: 'Save',
        accept: () => {
          RequestService.reopenRequest(this.request._id, this.new_closing_date.toUTCString())
            .then(response => {
              this.$toast.add({severity: 'success', summary: this.$t('request_detail.request_reopened'), life: 3000})
              this.request = response.data
            })
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
      });
    }
  }
}
</script>
<style scoped>
iframe {
  height: 600px;
}

.p-inputgroup-addon,
:deep(#speakers-container) {
  width: 100%;
}

.p-dropdown {
  max-width: 20vw;
}
</style>
